  import { PAGE_INFO_TYPE } from "../../types/globalConstantsTypes";

  export const PAGE_INFO: PAGE_INFO_TYPE = {
    "home": {
      SLUG: "/",
      TITLE: "ホーム",
      TITLE_EN: ""
    },
    //"ourVision": {
    //  SLUG: "our-vision",
    //  TITLE: "わたし達の想い",
    //  TITLE_EN: "",
    //  TITLE_COVER_CLASS: "cover_bg__ourvision"
    //},
    //"cloud": {
    //  SLUG: "cloud",
    //  TITLE: "クラウド経営ツール",
    //  TITLE_EN: ""
    //},
    "ourService": {
      SLUG: "our-service",
      TITLE: "経営支援サービス",
      TITLE_EN: "Our Service",
      TITLE_COVER_CLASS: "cover_bg__ourService",
      SUB: {
        // "businessPlan": {
        //   SLUG: "our-service/business-plan",
        //   TITLE: "事業計画・経営計画サポート",
        //   TITLE_EN: "BUSINESS PLAN",
        //   TITLE_COVER_CLASS: "cover_bg__businessPlan"
        // },
        "standardPlan": {
          SLUG: "our-service/standard-plan",
          TITLE: "スタンダードプラン",
          TITLE_EN: "Standard Plan",
          TITLE_COVER_CLASS: "cover_bg__standard_plan"
        },
        "plusMas": {
          SLUG: "our-service/plus-mas",
          TITLE: "短期収集中税務改善プラン +MAS",
          TITLE_EN: "Money Advisory Service",
          TITLE_COVER_CLASS: "cover_bg__plusMas"
        },
        "secondOpinion": {
          SLUG: "our-service/second-opinion",
          TITLE: "セカンドオピニオン",
          TITLE_EN: "Second Opinion",
          TITLE_COVER_CLASS: "cover_bg__secondOpinion"
        }
      }
    },

    "outline": {
      SLUG: "outline",
      TITLE: "事務所概要",
      TITLE_EN: "Outline",
      TITLE_COVER_CLASS: "cover_bg__outline"
    },
    "news": {
      SLUG: "news",
      TITLE: "お知らせ",
      TITLE_EN: "News",
      TITLE_COVER_CLASS: "cover_bg__news"
    },
    "seminar": {
      SLUG: "seminar",
      TITLE: "セミナー情報",
      TITLE_EN: "Seminar",
      TITLE_COVER_CLASS: "cover_bg__news"
    },
    // "info": {
    //   SLUG: "info",
    //   TITLE: "お知らせ",
    //   TITLE_EN: "",
    //   SUB: {
    //     "news": {
    //       SLUG: "info/news",
    //       TITLE: "お知らせ",
    //     },
    //     "seminar": {
    //       SLUG: "info/seminar",
    //       TITLE: "セミナー情報"
    //     }
    //   }
    // },
    "blog": {
      SLUG: "blog",
      TITLE: "税理士の税務・会計お役立ちブログ",
      TITLE_EN: "Blog",
      TITLE_COVER_CLASS: "cover_bg__news",
    },
    "voice": {
      SLUG: "voice",
      TITLE: "お客様の声",
      TITLE_EN: "Client Voice",
      TITLE_COVER_CLASS: "cover_bg__news",
    },
    "privacyPolicy": {
      SLUG: "privacy-policy",
      TITLE: "個人情報保護方針",
      TITLE_EN: "privacy Policy",
      TITLE_COVER_CLASS: "cover_bg__privacyPolicy",
    },
    "faq": {
      SLUG: "faq",
      TITLE: "よくあるご質問",
      TITLE_EN: "FAQ",
      TITLE_COVER_CLASS: "cover_bg__faq",
    },
    //"feePlan": {
    //  SLUG: "fee-plan",
    //  TITLE: "料金・プラン",
    //  TITLE_EN: "FEE PLAN"
    //},
    "contact": {
      SLUG: "contact",
      TITLE: "お問い合わせ",
      TITLE_EN: "Contact Us",
      TITLE_COVER_CLASS: "cover_bg__contact",
      IS_ON_FOOTER: true,
    }

  }

  export default PAGE_INFO;
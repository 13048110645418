import META from "../../../constants/siteInfo/meta";
import plt_formatDate from "../../../libs/plt_formatDate";
import ArticleVoiceItem from "../../molecules/ArticleVoiceItem/ArticleVoiceItem";
import ArticleVoiceItemsType from "./ArticleVoiceItemsType";

import styles from "./ArticleVoiceItems.module.scss"

import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay, Navigation, Pagination, Swiper as RealSwiper } from "swiper";
SwiperCore.use([Pagination, Navigation]);
RealSwiper.use([Autoplay]);

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

const ArticleVoiceItems = (props: ArticleVoiceItemsType) => {
  const {id, articleItems} = props;

  return (
    <>
      <div className={styles.swiperCarousel}>
        <Swiper
          loop={true}
          speed={2000}
          autoplay={{
            delay: 6000,
            disableOnInteraction: false,
          }}
          spaceBetween={25}
          slidesPerView={1}
          breakpoints={{
            768: {
              slidesPerView: 2
            },
            1024: {
              slidesPerView: 3
            }
          }}

          modules={[Navigation, Pagination]}
          navigation
          pagination={{ clickable: true }}
        >
          {articleItems.map((article, key) => (
            <SwiperSlide key={key}>
              <ArticleVoiceItem 
                id={article.id}
                imageItem={{
                  imgPCPath: article.eyecatch.url ? article.eyecatch.url + '?w=500' : "images/common/noimg.png",
                  altText: article.title
                }}
                articleTitle={{
                  text: article.title,
                  commonClass: "article_title_class",
                }}
                articleDate={{
                  date: article.publishedAt,
                  commonClass: "article_date_class",
                }}
                url={`${META.siteUrl}voice/post/?id=${article.id}`}
                name={article.name}
                area={article.area}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </>
  )
}
export default ArticleVoiceItems;
import META from "../../../constants/siteInfo/meta";
import ArticleItemWithImg from "../../molecules/ArticleItemWithImg/ArticleItemWithImg";
import ArticleItemsWithImgType from "./ArticleItemsWithImgType";
import styles from "./ArticleItemsWithImg.module.scss";

const ArticleItemsWithImg = (props: ArticleItemsWithImgType) => {
  const {id, articleItems, endpoint} = props;
  return (
    <>
      {articleItems? (
        <ul id={id} className={styles.articleBlog__items}>
          {articleItems.map((article, key) => (
            <li key={key} className={styles.articleBlog__item}>
            <ArticleItemWithImg
              id={article.id}
              imageItem={{
                imgPCPath: article.eyecatch ? article.eyecatch.url + '?w=500' : "/images/common/noimg.png",
                altText: article.title,
              }}
              articleDate={{
                id: "articleDate",
                date: article.publishedAt,
                commonClass: "article_date_class"
              }}
              articleTitle={{
                id: "articleTitleId",
                text: article.title,
                commonClass: "article_title_class"
              }}
              url={`${META.siteUrl}${endpoint}/post/?id=${article.id}`}
            />
          </li>
          ))}
        </ul>
      ): (
        <p>現在準備中です。</p>
      )}
    </>
  ) 
}

export default ArticleItemsWithImg;
import SectionLayoutBasic from "../../../components/layouts/SectionLayoutBasic/SectionLayoutBasic";
import ArticleVoiceItemsBlock from "../../../components/organisms/ArticleVoiceItemsBlock/ArticleVoiceItemsBlock";
import META from "../../../constants/siteInfo/meta";
import styles from "./HomeVoice.module.scss";

const HomeVoice = (props:any) => {
  const {voiceResponse} = props;
  return (
    <>
      <SectionLayoutBasic commonClass={`${styles.clientVoice} gray`}>
        <div className="secInner__block hidden">
          {voiceResponse ? (
            <ArticleVoiceItemsBlock 
              sectionTitleText={{
                text: "Client Voice",
                tagType: "h2",
                commonClass: "section_title_text"
              }}
              flashTitleText={{
                text: "お客様の声",
                commonClass: "flashTitleTextId"
              }}
              articleItems={{
                articleItems: voiceResponse,
                endpoint: "blog"
              }}
              linkButton={{
                text: "他のお客様の声を見る",
                url: `${META.siteUrl}voice/`,
                hasBorder: true
              }}
            />
          ) : ("Loading")}
        </div>
      </SectionLayoutBasic>
    </>
  )
}

export default HomeVoice;
"use client";

import { useEffect, useState } from "react";
import useFetchContentsForHome from "../hooks/useFetchContentsForHome.hooks";
import microCmsGetAuth from "../constants/apis/microCmsGetApiKey";
import ENDPOINTS from "../constants/apis/endpoints";
import useFetchVoicesForHome from "../hooks/useFetchVoicesForHome.hooks";
import { ContentsListHookResponseType } from "../types/microCmsHookResponseTypes";
import { microCmsAuthType } from "../types/microCmsAuthType";
import HomeMainView from "./_home/HomeMainView/HomeMainView";
import Manifest from "./_home/Manifest/Manifest";
import HomeOurService from "./_home/HomeOurService/HomeOurService";
import HomeBlog from "./_home/HomeBlog/HomeBlog";
import HomeVoice from "./_home/HomeVoice/HomeVoice";
import HomeNews from "./_home/HomeNews/HomeNews";

const Home = () => {
  const endpoints = ENDPOINTS;

  const [response, setResponse] = useState<ContentsListHookResponseType>();
  const [voiceResponse, setVoiceResponse] = useState<any>();
  const [flashInformationResponse, setFlashInformationResponse] = useState<any>();
  useEffect(()=> {
    const FetchData = async() => {

      // フック用クエリ情報を定数から取得
      const q_plt_HomeQueries = {"info": {"endpointId": endpoints["news"].ID, "limit": 3}, "blog": {"endpointId": endpoints["blog"].ID, "limit": 3}}
      const voiceHomeRequestQuery = {"endpointId": endpoints["voice"].ID, "limit": 5};

      const microCMSAuth: microCmsAuthType = microCmsGetAuth;
      // お知らせとブログの取得フック
      const homeContents = await useFetchContentsForHome(microCMSAuth, q_plt_HomeQueries);
      // console.log("homeContents: " + JSON.stringify(homeContents));  //debug;

      setResponse(homeContents);

      // MV下のお知らせを設定

      const category = homeContents?.responseBody["news"]?.contents[0].category[0];
      const categorySlug = category === "セミナー情報" ? "seminar" : category === "NEWS" ? "news" : "";

      const flashInformation = {
        id: "FlashInformation",
        flashTitle: {text: "news"},
        articleDate: {date: homeContents?.responseBody["news"]?.contents[0].publishedAt},
        articleTitle: {text: homeContents?.responseBody["news"]?.contents[0].title},
        url: `/${ENDPOINTS["news"].ID}/post/?id=${homeContents?.responseBody["news"]?.contents[0].id || ""}`
      };
      setFlashInformationResponse(flashInformation);

      // お客様の声取得フック
      const voiceContents = await useFetchVoicesForHome(microCMSAuth, voiceHomeRequestQuery)
      // console.log("voiceContents: " + JSON.stringify(voiceContents));  // debug
      setVoiceResponse(voiceContents.responseBody.contents)
    }
    FetchData();
  }, [endpoints]);

  return (
    <>
      <HomeMainView flashInformation={flashInformationResponse} />
      <Manifest />
      <HomeOurService />
      <HomeVoice voiceResponse={voiceResponse} />
      <HomeNews response={response} />
      <HomeBlog response={response} />
    </>
  )
}

export default Home;
const META = {
  "siteUrl": "https://tanabetax.com/",
  "siteTitle": "福岡市中央区の「たなべ税理士事務所」",
  "keywords": "福岡,市,税理士,会計,事務所,記帳代行,税務,経営支援",
  "description": "福岡市の「たなべ税理士事務所」。地下鉄薬院駅近くの会計事務所。税務代理、税務監査、決算報告まで経営者目線にたった税務・会計サービスを提供。初回相談は無料です。",
  "copyright": "© 2021 福岡市中央区「たなべ税理士事務所」",
  "gtmTagId": "GTM-T69VNJV9"
}

export default META;

export const urlChoices = {
  production: "https://tanabetax.com/",  // 本番
  staging: "https://stg.tanabetax.com/",  // STG用URL
  local: "http://localhost:3000/" // ローカル検証
}